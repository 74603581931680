<template>
  <div>
    <stimulsoft-editor
      ref="editor"
      @salvar="salvarCertificado"
    />
  </div>
</template>
<script>
import ProcedimentoEletronicoService from '@common/services/cadastros/ProcedimentoEletronicoService';
import { StimulsoftEditor } from '@components/misc';
export default {
  components: {
    StimulsoftEditor,
  },
  props: ['id'],
  data() {
    return {
      dataSource: null
    };
  },  
  computed: {
    ehRevisao() {
      return this.$route.name === 'procedimento-eletronico-certificado-revisao';
    },
  },
  mounted: function () {
    const { name } = this.$route;
    if (name === 'procedimento-eletronico-certificado') {
      if (this.id) this.buscarDataSource();
      return;
    }

    if (this.id) this.buscarCertificadoPorRevisao();
  },
  methods: {
    buscarCertificado: function () {
      ProcedimentoEletronicoService.buscarCertificado(this.id, false)
        .then((res) => {
          this.$refs.editor.montarComponente(res.data.certificado);
        })
        .catch(() => {
          this.$refs.editor.montarComponente();
        });
    },
    buscarCertificadoPorRevisao: function () {
      ProcedimentoEletronicoService.buscarCertificadoPorRevisao(
        this.id,
        false
      ).then((res) => {
        this.$refs.editor.montarComponente(res.data.certificado);
      });
    },
    buscarDataSource: function () {
      ProcedimentoEletronicoService.buscarDataSource(this.id).then((res) => {
        this.dataSource = res.data;
        console.clear();
        console.log(this.dataSource);
        this.$refs.editor.carregarDataSource(res.data);
        //this.buscarCertificado(); //Verificar com o Vini
        this.buscarCertificadoPorRevisao();
      });
    },
    salvarCertificado: function (certificado) {
      if (this.ehRevisao) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      //ProcedimentoEletronicoService.atualizarCertificado(this.id, certificado)
      ProcedimentoEletronicoService.atualizarCertificado(this.dataSource.procedimentoEletronico.id, certificado)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.procedimentos_eletronicos.certificado_salvo`)
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
